@tailwind base;
@tailwind components;
@tailwind utilities;

/* Assumes navbar height = 12; */
.h-screen-adj {
  height: calc(100vh - 48px);
}

.min-h-screen-adj {
  min-height: calc(100vh - 48px);
}